import React, { useState, useRef } from "react";
import {
  Button,
  Row,
  Modal,
  Input,
  InputGroup,
  Form,
} from "reactstrap";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import getNotify  from '../../../../helpers/getNotify';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

const ShareDocumentModal = (props) => {
    const notifyRef = useRef();
    const { isOpen, toggle, data } = props;
    
    const [userAddress, setUserAddress] = useState('');
    const [usersSelected, setUsersSelected] = useState([]);
  
    const shareDocument = async () => {
      try {
        const allUsersToShare = new Array();
        
        for(const user of usersSelected) {
          const responseUser = await axios({
            method: 'GET',
            url: BASE_URL + `/api/oneblock/users/share-users`,
            params: {
              email: user
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
              "x-api-key": API_KEY,
              "x-client-id": CLIENT_ID,
            },
          })

          if(!responseUser.data.data.length) throw new Error(`User of email ${user} doesn't exist`)
          allUsersToShare.push(responseUser.data.data[0].Id);
        }

        if(!allUsersToShare.length) throw new Error('No Users found')

        await axios({
          method: 'POST',
          url: BASE_URL + `/api/oneblock/permission/share/document`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "x-api-key": API_KEY,
            "x-client-id": CLIENT_ID,
          },
          data: {
            documentAddress: data?.contract_address,
            user_shared_id : allUsersToShare,
            role : 1
          }
        })

        getNotify(notifyRef, 'success', 'Success', 'Document succesfully shared');

        setUserAddress('')
        setUsersSelected([])
        
      } catch (error) {
        getNotify(notifyRef, 'danger', 'Failed to Share Document', error.message || error.response?.data.error);
        setUserAddress('')
        setUsersSelected([])
      }
    };
  
    const handleSubmit = () => {
      shareDocument();
      toggle();
    };
  
    const handleRemoveUser = (index) => {
      let newUsersSelected = [...usersSelected];
      newUsersSelected.splice(index, 1);
      setUsersSelected(newUsersSelected);
    };
  
    return (
      <>
        <NotificationAlert ref={notifyRef} />
        <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={toggle}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Share Document
            </h5>
  
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
  
          <div className="modal-body">
            <Form onSubmit={e => {
              e.preventDefault()
              setUsersSelected([...usersSelected, e.target[0].value])
              setUserAddress('');
            }}>
              <label htmlFor="">Choose User by Email</label>
              <InputGroup>
                <Input
                  value={userAddress}
                  id="chooseUser"
                  placeholder="Choose User..."
                  type="text"
                  onChange={e => setUserAddress(e.target.value)}
                />
                <Button
                  color="primary"
                  type="submit"
                >
                  Select
                </Button>
              </InputGroup>
            </Form>
  
            <Row className="my-4 justify-content-center">
              { usersSelected.map((item, index) => {
                return <div
                  style={{
                    border: '1px solid black',
                    padding: '8px 12px',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    margin: '0 5px 5px'
                  }}
                >
                  {item}
                  <button
                    style={{
                      marginLeft: '5px',
                      border: 'none',
                      background: 'none',
                      fontWeight: 'bold'
                    }}
                    onClick={() => handleRemoveUser(index)}
                  >X</button>
                </div>
              })}
            </Row>
          </div>
  
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={handleSubmit}>
              Save changes
            </Button>
          </div>
        </Modal>
      </>
    );
};

export default ShareDocumentModal;