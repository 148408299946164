import CustomLogin from "views/pages/login";
import User from "views/pages/User";
import File from "views/pages/File";
import Activity from "components/Activity";
import Profile from "views/pages/Profile";
import Profiles from "views/pages/examples/Profile";
import Documents from "views/pages/Document/Documents";
import Overview from "views/pages/Overview";
import Tracebility from "views/pages/Tracebility";
import SharedFilePage from "views/pages/SharedFile/SharedFile";
import { Home, People, ShieldDone, Paper, Swap, Activity as ActivityIcon, Graph } from "react-iconly";
import DocumentShared from "views/pages/DocumentShared";

const userRoutes = [
  {
    path: "/dashboard",
    name: "Overview",
    icon: Home,
    component: Overview,
    layout: "/user",
  },
  {
    path: "/people",
    name: "People",
    icon: People,
    component: User,
    layout: "/user",
  },
  {
    path: "/data",
    name: "Data",
    icon: Paper,
    component: File,
    layout: "/user",
  },
  {
    path: "/shared-data",
    name: "Shared Data",
    icon: Graph,
    component: SharedFilePage,
    layout: "/user",
  },
  {
    path: "/onetrace",
    name: "OneTrace",
    icon: ShieldDone,
    component: Tracebility,
    layout: "/user",
  },
  {
    collapse: true,
    name: "Activity",
    state: "activityCollapse",
    icon: ActivityIcon,
    views: [
      {
        path: "/activity/login",
        name: "Successful Login",
        miniName: "LGN",
        component: () => {
          return <Activity title="Successful Login" activity="login" />;
        },
        layout: "/user",
      },
      {
        path: "/activity/register",
        name: "Successful Registration",
        miniName: "REG",
        component: () => {
          return (
            <Activity title="Successful Registration" activity="register" />
          );
        },
        layout: "/user",
      },
      {
        path: "/activity/login-attempt",
        name: "Login Attempt",
        miniName: "LGA",
        component: () => {
          return <Activity title="Login Attempt" activity="login" />;
        },
        layout: "/user",
      },
      {
        path: "/activity/file-upload",
        name: "File Upload",
        miniName: "FUP",
        component: () => {
          return <Activity title="File Upload" activity="UploadFile" />;
        },
        layout: "/user",
      },
      {
        path: "/activity/view-document",
        name: "View Document",
        miniName: "VWD",
        component: () => {
          return <Activity title="View Documents" activity="ViewCount" />;
        },
        layout: "/user",
      },
      {
        path: "/activity/download-document",
        name: "Download Document",
        miniName: "DWD",
        component: () => {
          return <Activity title="Download Documents" activity="DownloadCount" />;
        },
        layout: "/user",
      },
      {
        path: "/activity/edit-document",
        name: "Edit Document",
        miniName: "EDT",
        component: () => {
          return <Activity title="Edit Document" activity="EditFile" />;
        },
        layout: "/user",
      },
      // {
      //   path: "/activity/set-description",
      //   name: "Set Description",
      //   miniName: "SDC",
      //   component: () => {
      //     return <Activity title="Set Description" activity="setDescription" />;
      //   },
      //   layout: "/user",
      // },
    ],
  },
  {
    collapse: true,
    name: "Data Log",
    state: "logCollapse",
    icon: Swap,
    views: [
      {
        path: "/log/data",
        name: "Logs",
        miniName: "LGS",
        component: () => {
          return <Activity title="Data Log" activity="DataLog" />;
        },
        layout: "/user",
      },
    ],
  },
  {
    path: "/login",
    name: "",
    component: CustomLogin,
    layout: "/auth",
  },
  {
    path: "/profile/:id",
    name: "",
    miniName: "",
    component: Profile,
    layout: "/user",
  },
  {
    path: "/documents/:address",
    name: "",
    miniName: "",
    component: Documents,
    layout: "/user",
  },
  {
    path: "/shared-document/:address",
    name: "",
    miniName: "",
    component: DocumentShared,
    layout: "/user",
  },
  {
    path: "/profiles",
    name: "",
    miniName: "",
    component: Profiles,
    layout: "/user",
  },
];

export default userRoutes;
