import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardHeader,
  Container,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Document } from "react-iconly";
import CustomTable from "components/Table";
import { checkJSON } from "helpers/checkJSON";

const moment = require("moment");

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

const Activity = (props) => {
  const { title, activity } = props;
  const [data, setData] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [targetAddress, setTargetAddress] = useState("");
  const [error, setError] = useState(null);

  const [modalDetail, setModalDetail] = useState(false);
  const [list, setList] = useState();

  const columns = [
    {
      dataField: "tx_hash",
      text: "Tx Hash",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <>
            <CopyToClipboard text={cellContent}>
              <span>
                {cellContent.substring(0, 8)}...
                {cellContent.substring(60, 66)}
                <i
                  data-placement="top"
                  id={"tooltip" + cellContent}
                  style={{ cursor: "pointer" }}
                  className="ml-2 far fa-copy"
                />
              </span>
            </CopyToClipboard>
            <UncontrolledTooltip
              delay={0}
              trigger="hover focus"
              placement="top"
              target={"tooltip" + cellContent}
            >
              Copy to clipboard
            </UncontrolledTooltip>
          </>
        );
      },
    },
    {
      dataField: "role",
      text: "Role",
      sort: true,
      formatter: (cellContent, row) => {
        const isAdmin = cellContent == 'admin';
        return (
          <span
            className={`${isAdmin ? "text-success" : "text-info"
              } font-weight-bold`}
          >
            {isAdmin ? "Admin" : "User"}
          </span>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: (cellContent, row) => {
        return <span className="font-weight-bolder">{cellContent}</span>;
      },
    },
    {
      dataField: "user_address",
      text: "Address",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <>
            <CopyToClipboard text={cellContent}>
              <span>
                {cellContent.substring(0, 8)}...
                {cellContent.substring(36, 42)}
                <i
                  data-placement="top"
                  id={"tooltip" + row.tx_hash + cellContent}
                  style={{ cursor: "pointer" }}
                  className="ml-2 far fa-copy"
                />
              </span>
            </CopyToClipboard>
            <UncontrolledTooltip
              delay={0}
              trigger="hover focus"
              placement="top"
              target={"tooltip" + row.tx_hash + cellContent}
            >
              Copy to clipboard
            </UncontrolledTooltip>
          </>
        );
      },
    },
    {
      dataField: "Document_address",
      text: "Contract Address",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <>
            <CopyToClipboard text={cellContent}>
              <span>
                {cellContent.substring(0, 8)}...
                {cellContent.substring(36, 42)}
                <i
                  data-placement="top"
                  id={"tooltip" + row.tx_hash + cellContent}
                  style={{ cursor: "pointer" }}
                  className="ml-2 far fa-copy"
                />
              </span>
            </CopyToClipboard>
            <UncontrolledTooltip
              delay={0}
              trigger="hover focus"
              placement="top"
              target={"tooltip" + row.tx_hash + cellContent}
            >
              Copy to clipboard
            </UncontrolledTooltip>
          </>
        );
      },
    },
    // {
    //   dataField: "data",
    //   text: "To",
    //   sort: true,
    //   hidden: activity !== "setPermission",
    //   formatter: (cellContent, row) => {
    //     const data = JSON.parse(cellContent);
    //     if (data.set_to_address) {
    //       return (
    //         <>
    //           <CopyToClipboard text={data.set_to_address}>
    //             <span>
    //               {data.set_to_address.substring(0, 8)}...
    //               {data.set_to_address.substring(36, 42)}
    //               <i
    //                 data-placement="top"
    //                 id={"tooltip" + row.transactionHash + data.set_to_address}
    //                 style={{ cursor: "pointer" }}
    //                 className="ml-2 far fa-copy"
    //               />
    //             </span>
    //           </CopyToClipboard>
    //           <UncontrolledTooltip
    //             delay={0}
    //             trigger="hover focus"
    //             placement="top"
    //             target={"tooltip" + row.transactionHash + data.set_to_address}
    //           >
    //             Copy to clipboard
    //           </UncontrolledTooltip>
    //         </>
    //       );
    //     } else {
    //       return null;
    //     }
    //   },
    // },
    {
      dataField: "timestamp",
      text: "Timestamp",
      sort: true,
      formatter: (cellContent, row) => {
        const date = moment(cellContent);
        // const formatted = date.format("DD/MM/yyyy hh:mm:ss");
        const humanized = date.fromNow();
        return (
          <>
            <span data-palacement="top" id={"tooltip" + cellContent}>
              {humanized}
            </span>
            {/* <UncontrolledTooltip
              delay={0}
              placement="top"
              target={"tooltip" + cellContent}
            >
              {formatted}
            </UncontrolledTooltip> */}
          </>
        );
      },
    },
    {
      dataField: "user_agent",
      text: "User Agent",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <>
            {cellContent.length > 30
              ? cellContent.substring(0, 30) + "..."
              : cellContent}
          </>
        );
      },
    },
    {
      dataField: "detail",
      text: "Detail",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div style={{ justifyContent: 'center' }}>
            <Document
                stroke="bold"
                filled={true}
                role="button"
                // style={{ float: "left" }}
                onClick={() => {
                  // if (row?.data[0] === "'") {
                  //   const parsed = JSON.parse(row?.data.split("'")[1]);
                  //   row.data = parsed;
                  // }
                  showModal(row);
                }}
              />
          </div>
        );
      },
    },
  ];

  const showModal = (row) => {
    setModalDetail(!modalDetail);
    setList(row);
  };

  const fetchTableData = useCallback(async () => {
    return axios
      .get(
        BASE_URL +
        // `/api/oneblock/activity-log/all-activity?page=${page}&limit=${limit}&activityLog=${activity}&userAddr=${targetAddress}`,
        `/api/oneblock/activity-log/all-activity?page=${page}&limit=${limit}&activityLog=${activity}&address=${targetAddress}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "x-api-key": API_KEY,
            "x-client-id": CLIENT_ID,
          },
        }
      )
      .then((response) => {
        setData(response.data.data);
        setTotal(response.data.total);
        setError("");
      })
      .catch((e) => {
        setData([]);
        setTotal(0);
        setError(e.response.data.message);
      });

  }, [limit, page, targetAddress]);

  const handleTableChange = (type, { page, sizePerPage }) => {
    setLimit(sizePerPage);
    setPage(page);
  };

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  return (
    <>
      <Container className="mt--6" fluid style={{ fontFamily: "Nunito" }}>
        {error && (
          <Alert color="danger">
            <span className="alert-inner--icon">
              <i className="fas fa-times-circle" />
            </span>{" "}
            <span className="alert-inner--text ml-1">
              <strong>Error: </strong>
              {error}
            </span>
          </Alert>
        )}
        <Card>
          <CardHeader className="border-0">
            <h3 className="mb-0">{title}</h3>
          </CardHeader>
          <div className="table-responsive">
            {data && (
              <CustomTable
                data={data}
                page={page}
                sizePerPage={limit}
                columns={columns}
                totalSize={total}
                onTableChange={handleTableChange}
                searchState={setTargetAddress}
              />
            )}
          </div>
        </Card>
      </Container>

      <Modal
        className="modal-dialog-centered"
        isOpen={modalDetail}
        toggle={() => setModalDetail(!modalDetail)}
      >
        <ModalHeader toggle={() => setModalDetail(!modalDetail)}>
          <p className="modal-title" id="modal-title-notification">
            Additional Data of {list?.action}
          </p>
        </ModalHeader>
        <ModalBody>
          <pre className="bg-secondary border text-gray-dark rounded px-3 py-2 m-0">
            {list?.data && JSON.stringify(list?.data, null, 4)}
          </pre>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Activity;
