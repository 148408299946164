import React, { useState, useEffect, useRef } from "react";

// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import Logo from "assets/img/brand/logo_oneblock.svg";

// router
import { useHistory } from "react-router-dom";

import axios from "axios";
import NotificationAlert from "react-notification-alert";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

function Login() {
  const history = useHistory();

  const notifyRef = useRef();

  const [isLoading, setIsLoading] = React.useState(false);
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChanged = ({ target }) => {
    setEmail(target.value);
  };

  const handlePasswordChanged = ({ target }) => {
    setPassword(target.value);
  };

  const getNotify = (ref, type, title, message) => {
    let options = {
        place: "tr",
        message: <>
            <div className="alert-text"></div>
            <span className="alert-title" style={{ fontWeight: 'bold' }} data-notify="title">
                {title}
            </span>

            <div>
                <span data-notify="message"> {message}</span>
            </div>
        </>,
        type: type,
        icon: "ni ni-bell-55 mr-2",
        autoDismiss: 2,
    };
    ref.current.notificationAlert(options);
};

  useEffect(() => {
    // Update the document title using the browser API
  });

  if (localStorage.getItem("loggedIn") !== null) {
    history.push("/admin");
  }

  function handleClick() {
    setIsLoading(true)
    const json = { email: email, password: password, action: "login" };
    axios
      .post(BASE_URL + `/api/oneblock/auth/login`, json, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "x-api-key": API_KEY,
          "x-client-id": CLIENT_ID,
        },
      })
      .then((response) => {
        setIsLoading(false)
        localStorage.setItem("loggedIn", true);
        localStorage.setItem("accessToken", response.data.data.accessToken);
        localStorage.setItem("role", response.data.data.role);
        if (localStorage.getItem("loggedIn") !== null) {
          getNotify(notifyRef, "success", "Success", "Success login");
          setTimeout(() => {
            if(response.data.data.role == 'admin') {
              history.push("/admin");
            } else {
              history.push("/user");
            }
          }, 2000);
        }
      })
      .catch((error) => {
        setIsLoading(false)
        getNotify(notifyRef, "danger", "Failed", error.response?.data.error || "Failed to login");
      });
  }

  return (
    <Container className="mt-9 pb-5">
      <NotificationAlert ref={notifyRef} />
      <Row className="justify-content-center overflow-hidden">
        <Col xs="4" lg="4" className="d-flex align-items-center">
          <img src={Logo} className="img-center w-100" />
        </Col>

        {/* I hate writing css just for media queries, so here's my workaround */}
        <Col lg="1" className="d-none d-sm-block" />
        <Col lg="1" className="border-left d-none d-sm-block" />

        <Col xs="12" md="8" lg="6" className="mt-4">
          <div className="text-center text-muted mb-4">
            <small>Selamat datang di Dashboard Oneblock</small>
          </div>
          <Form
            role="form"
            onSubmit={e => e.preventDefault()}
          >
            <FormGroup
              className={classnames("mb-3", {
                focused: focusedEmail,
              })}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email"
                  type="email"
                  onChange={handleEmailChanged}
                  value={email}
                  onFocus={() => setfocusedEmail(true)}
                  onBlur={() => setfocusedEmail(true)}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup
              className={classnames({
                focused: focusedPassword,
              })}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Password"
                  type="password"
                  onChange={handlePasswordChanged}
                  value={password}
                  onFocus={() => setfocusedPassword(true)}
                  onBlur={() => setfocusedPassword(true)}
                />
              </InputGroup>
            </FormGroup>
            <div className="text-center">
              <Button
                disabled={isLoading}
                className="my-4"
                style={{
                  // backgroundColor: "#0eb7b9",
                  backgroundColor: "#4A5CA9",
                  borderColor: "transparent",
                  color: "white",
                }}
                type="submit"
                onClick={handleClick}
              >
                Login
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
