import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Modal,
  Input,
  FormGroup,
} from "reactstrap";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import getNotify  from '../../../../helpers/getNotify';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const EditDocumentModal = (props) => {
    const notifyRef = useRef();
    const { isOpen, toggle, data } = props;
    
    const [formData, setFormData] = useState({
      title: data?.title,
      description: data?.description,
    });

    const editDetail = () => {
      axios({
        method: 'PUT',
        url: BASE_URL + `/api/oneblock/file/edit`,
        params: {
          document_address: data.contract_address
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        data: {
          title : formData.title,
          description: formData.description
        }
      })
      .then((response) => {
        getNotify(notifyRef, 'success', 'Success', 'Document succesfully saved');
      })
      .catch(error => {
        getNotify(notifyRef, 'danger', 'Failed', 'Failed to Edit Document');
      });
    };
  
    const handleSubmit = () => {
      editDetail();
      toggle();
    };
  
    return (
      <>
        <NotificationAlert ref={notifyRef} />
        <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={toggle}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Document
            </h5>
  
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
  
          <div className="modal-body">
            <FormGroup>
              <label htmlFor="titleInput">Title</label>
              <Input
                id="titleInput"
                placeholder="Title"
                type="text"
                defaultValue={data?.title}
                onChange={(e) =>
                  setFormData((prev) => {
                    return {
                      ...prev,
                      title: e.target.value,
                    };
                  })
                }
              />
            </FormGroup>
  
            <FormGroup>
              <label htmlFor="descriptionInput">Description</label>
              <Input
                type="textarea"
                id="descriptionInput"
                rows="3"
                defaultValue={data?.description}
                onChange={(e) =>
                  setFormData((prev) => {
                    return {
                      ...prev,
                      description: e.target.value,
                    };
                  })
                }
              />
            </FormGroup>
          </div>
  
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={handleSubmit}>
              Save changes
            </Button>
          </div>
        </Modal>
      </>
    );
};

export default EditDocumentModal;