import LightGallery from "lightgallery/react";

import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

import pdfImage from '../../assets/img/default/pdf.png'

function Lightbox(props) {
  const { src, alt, size, mode = "default" } = props;

  const onInit = () => {
    console.log("lightGallery has been initialized");
  };

  const handleShowPDF = (e, base64URL) => {
    e.preventDefault();
    const win = window.open();
    win.document.write(`
      <body style="margin: 0; padding: 0;">
        <iframe
          src="${base64URL}"
          frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;"
          allowfullscreen
        >
        </iframe>
      </body>`
    );
  }

  return (
    <>
      {src.map((link, index) => {
        return link.includes('.pdf') || src[0].includes('application/pdf')
          ? <a href={link} onClick={(e) => handleShowPDF(e, link)} key={alt[index]} data-src={link} hidden={!!index}>
                <span
                  className={mode === "avatar" ? `avatar avatar-${size} m-0` : `w-100`}
                >
                  <img
                    alt={alt[index]}
                    src={pdfImage}
                    className="h-100 w-100"
                    style={{ objectFit: "cover" }}
                  />
                </span>
            </a>
          : <LightGallery onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}>
              <a href={link} key={alt[index]} data-src={link} hidden={!!index}>
                <span
                  className={mode === "avatar" ? `avatar avatar-${size} m-0` : `w-100`}
                >
                  <img
                    alt={alt[index]}
                    src={link}
                    className="h-100 w-100"
                    style={{ objectFit: "cover" }}
                  />
                </span>
              </a>
            </LightGallery>
      })}


      {/* <LightGallery onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}>
        {src.map((link, index) => {
          return (
            <a href={link} key={alt[index]} data-src={link} hidden={!!index}>
              <span
                className={mode === "avatar" ? `avatar avatar-${size} m-0` : `w-100`}
              >
                <img
                  alt={alt[index]}
                  src={link}
                  className="h-100 w-100"
                  style={{ objectFit: "cover" }}
                />
              </span>
            </a>
          )
        })}
      </LightGallery> */}
    </>
  );
}

export default Lightbox;
