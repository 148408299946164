import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
} from "reactstrap";
import audit from "assets/img/icons/common/audit.svg";
import accountability from "assets/img/icons/common/accountability.svg";
import integrity from "assets/img/icons/common/integrity.svg";
import tracebility from "assets/img/icons/common/tracebility.svg";
import { useHistory } from "react-router";

function Overview() {
  const history = useHistory();

  return (
    <>
      <Container className="mt--7 pb-6" fluid>
        <Row className="flex-row-reverse align-items-center">
          <img
            src={audit}
            className="col-sm-4 w-100 px-6 px-sm-0 px-lg-6 mb-4"
          />
          <div className="col-sm-8 mb-4 mt-sm-4">
            <p className="h1 text-primary font-weight-bolder">
              Audit Trail System
            </p>

            <div className="d-flex">
              <p className="font-weight-normal">
                Most audit and audit trail softwares store the data in
                relational databases. which can be easily accessed and altered,
                leading to lack of trust in the process. Cybersecurity is not
                the only solution to this problem. There must be a facility to
                store transactions of audit trail that can not be tampered or
                immutable, trusted, and traceable.
              </p>
            </div>
          </div>
        </Row>
        <Row className="mx-lg--2">
          <Card
            className="p-0 border w-100"
            style={{ cursor: "pointer", background: "#808DA0" }}
            onClick={() => localStorage.getItem('role') == 'admin' ? history.push("/admin/people") : null}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs="2" className="text-center">
                  <img src={accountability} />
                </Col>
                <Col>
                  <CardTitle className="h2 text-white font-weight-bolder mb-2">
                    Accountability
                  </CardTitle>
                  <CardText className="text-white">
                    Everyone in the system will have roles that govern their
                    accessibility to a particular entity, such as access to apps
                    and uploaded files. Oneblock is ensuring all data stored has
                    its accountability by recording who, what, and when.
                  </CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card
            className="p-0 border w-100"
            style={{ cursor: "pointer", background: "#808DA0" }}
            onClick={() => history.push(`/${localStorage.getItem('role')}/data`)}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs="2" className="text-center">
                  <img src={integrity} />
                </Col>
                <Col>
                  <CardTitle className="h2 text-white font-weight-bolder mb-2">
                    Integrity
                  </CardTitle>
                  <CardText className="text-white">
                    Immutability is one of the OneBlock's key features to make
                    all logged transactions remain permanent and unaltered.
                    Immutability has the potential to transform the auditing
                    process into a quick, efficient, and makes sure all of
                    recorded data can have integrity.
                  </CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card
            className="p-0 border w-100"
            style={{ cursor: "pointer", background: "#808DA0" }}
            onClick={() => localStorage.getItem('role') == 'admin' ? history.push("/admin/onetrace") : null}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs="2" className="text-center">
                  <img src={tracebility} />
                </Col>
                <Col>
                  <CardTitle className="h2 text-white font-weight-bolder mb-2">
                    Tracebility
                  </CardTitle>
                  <CardText className="text-white">
                    OneBlock can provide "timestamp" proof on any transaction.
                    This makes sure that the history of transactions is
                    available and can be fetched at any point in time for the
                    purpose of audit. Everyone can trace all transactions
                    happening in the system.
                  </CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
      </Container>
    </>
  );
}

export default Overview;
