import React, { useRef, useState, useEffect, useCallback } from "react";
import "@fontsource/nunito";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Badge,
  UncontrolledTooltip,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classnames from "classnames";
import NotificationAlert from "react-notification-alert";
import { CopyToClipboard } from "react-copy-to-clipboard";
import getNotify  from '../../helpers/getNotify';

import moment from "moment";

import axios from "axios";
import { useHistory } from "react-router";
import CustomTable from "components/Table";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

function Profile({ match }) {
  const history = useHistory();
  const {
    params: { id },
  } = match;

  const [isLoading, setIsLoading] = useState(false);
  
  const [user, setUser] = useState(null);
  const [dokumen, setDokumen] = useState(false);
  const [log, setLogs] = useState([]);
  const [data, setData] = useState([]);
  const [datapoint, setDatapoint] = useState([]);
  const [tabIndex, setTabIndex] = useState(1);
  const [modal, setModal] = useState(false);
  // Activity Table
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [dataActivities, setDataActivities] = useState([]);

  const toggle = () => setModal(!modal);

  const hideDocument = () => {
    setDokumen(false);
    setDatapoint([]);
  };

  const handleGetAllUserDocument = (contract_address = '') => {
    axios
      .post(
        BASE_URL + `/api/oneblock/user/activity/datapoint?contract_address=${contract_address}`,
        { createdBy: user.address },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      )
      .then((response) => {
        setData(response.data.data);
      });
  }

  const fetchTableData = useCallback(async () => {
    return axios
      .get(
        BASE_URL +
        `/api/oneblock/user/activity?page=${page}&limit=${limit}&address=${user?.address}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      )
      .then((response) => {
        setDataActivities(response.data.data);
        setTotal(response.data.total);
      })
      .catch((e) => {
        setDataActivities([]);
        setTotal(0);
      });

  }, [limit, page, user]);

  const handleTableChange = (type, { page, sizePerPage }) => {
    setLimit(sizePerPage);
    setPage(page);
  };

  useEffect(() => {
    if (user) {
      fetchTableData();
    }
  }, [fetchTableData]);

  useEffect(() => {
    if (user) {
      handleGetAllUserDocument()
      fetchTableData();
    }
  }, [user]);

  useEffect(() => {
    axios
      .get(BASE_URL + `/api/oneblock/users/` + id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "x-api-key": API_KEY,
          "x-client-id": CLIENT_ID,
        },
      })
      .then((response) => {
        setUser(response.data.data);
      });
  }, []);

  return (
    <>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs="12">
            <Card className="w-100 border">
              {user && (
                <CardBody>
                  <Row className="d-flex align-items-center">
                    <Col xs="auto" className="">
                      <div className="avatar avatar-xl rounded-circle">
                        <i className="ni ni-single-02 display-2" />
                      </div>
                    </Col>

                    <Col className="w-100">
                      <Row
                        style={{ fontWeight: "bold" }}
                        className="text-capitalize d-flex align-items-center"
                      >
                        {user.first_name} {user.last_name} <Badge className="ml-2 py-1 px-2 text-capitalize" size="small" color={user.role === " admin" ? "primary" : "success"} pill>{user.role}</Badge>
                      </Row>
                      <Row style={{ fontSize: "11px" }}>
                        {user.address.substring(0, 8)}...
                        {user.address.substring(36, 44)}
                        <CopyToClipboard text={user.address}>
                          <span>
                            <i
                              data-placement="top"
                              id={"tooltip" + user.address}
                              style={{ cursor: "pointer" }}
                              className="ml-2 far fa-copy"
                            />
                          </span>
                        </CopyToClipboard>
                        <UncontrolledTooltip
                          delay={0}
                          trigger="hover focus"
                          placement="top"
                          target={"tooltip" + user.address}
                        >
                          Copy to clipboard
                        </UncontrolledTooltip>
                      </Row>
                      <Row>{user.email}</Row>
                    </Col>
                  </Row>
                </CardBody>
              )}
            </Card>
          </Col>

          <Col xs="12">
            <Nav
              className="nav-fill mb-2 flex-column flex-md-row"
              id="tabs-icons-text"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  aria-selected={tabIndex === 1}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: tabIndex === 2 || tabIndex === 3 ,
                  })}
                  onClick={(e) => setTabIndex(1)}
                  href="#pablo"
                  role="tab"
                >
                  <i className="ni ni-circle-08 mr-2" />
                  Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={tabIndex === 2}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: tabIndex === 1 || tabIndex === 3,
                  })}
                  onClick={(e) => setTabIndex(2)}
                  href="#pablo"
                  role="tab"
                >
                  <i className="ni ni-archive-2 mr-2" />
                  Dokumen
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={tabIndex === 3}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: tabIndex === 2 || tabIndex === 1,
                  })}
                  onClick={(e) => setTabIndex(3)}
                  href="#pablo"
                  role="tab"
                >
                  <i className="ni ni-archive-2 mr-2" />
                  Activity
                </NavLink>
              </NavItem>
            </Nav>

            <Card className="shadow">
              <CardBody>
                <TabContent activeTab={"tabs" + tabIndex}>
                  <TabPane tabId="tabs1">
                    <Row>
                      <Col xs="8">
                        <h3 className="mb-0">Edit profile</h3>
                      </Col>
                      <Col className="text-right" xs="4"></Col>
                    </Row>

                    <Form>
                      <div className="mt-2">
                        {user && <EditProfile user={user} isLoading={isLoading} setIsLoading={setIsLoading} />}
                      </div>
                    </Form>

                    { localStorage.getItem('role') == 'admin' && user?.role == 'user' && (
                      <>
                        <Row className="mt-3">
                          <Col xs="8">
                            <h3 className="mb-0">Edit Role</h3>
                          </Col>
                          <Col className="text-right" xs="4"></Col>
                        </Row>
                        <Form>
                          <div className="mt-2">
                            {user && <EditRole user={user} isLoading={isLoading} setIsLoading={setIsLoading} />}
                          </div>
                        </Form>
                      </>
                    )}
                  </TabPane>

                  <TabPane tabId="tabs2">
                    <p>{data.length} Documents</p>
                    <Form
                      className="mb-4"
                      onSubmit={(e) => {
                        e.preventDefault()
                        handleGetAllUserDocument(e.target[0].value);
                      }}
                    >
                      <Input
                        type="search"
                        name="search"
                        id="exampleSearch"
                        placeholder="Cari dokumen"
                      />
                    </Form>
                    <Row className="px-4">
                      <div
                        style={{
                          display: "grid",
                          width: "100%",
                          gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
                          gap: "1rem",
                        }}
                      >
                        {data.map((obj, index) => {
                          const formatted = moment(obj.timestamp).format("LL");

                          return (
                            <Card
                              key={index}
                              className="d-flex flex-column align-items-center shadow-none bg-transparent p-2 border"
                              style={{
                                gridColumn: "span 3 / span 3",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                history.push(
                                  `/${localStorage.getItem('role')}/documents/${obj.contract_address}`
                                )
                              }
                            >
                              <div className="d-flex flex-column align-items-center">
                                <p className="text-capitalize font-weight-bold mb-0">
                                  {obj.name}
                                </p>
                                <p className="text-sm">
                                  {obj.contract_address.substring(0, 4)}...
                                  {obj.contract_address.substring(39, 42)}
                                </p>
                              </div>

                              <i className="far fa-file-alt display-2 text-muted" />

                              <div
                                className="text-light"
                                style={{ fontSize: "10px" }}
                              >
                                {formatted}
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    </Row>
                  </TabPane>

                  <TabPane tabId="tabs3">
                    <p>{data.length} Activities</p>
                    <Row className="px-4">
                      <ActivityTable
                        user={user}
                        data={dataActivities}
                        limit={limit}
                        total={total}
                        handleTableChange={handleTableChange}
                      />
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

const EditProfile = ({ user, isLoading, setIsLoading }) => {
  const notifyRef = useRef();

  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);

  const [activity, setActivity] = useState(user.menu.activity || 0);
  const [tracebility, setTracebility] = useState(user.menu.tracebility || 0);
  const [users, setUsers] = useState(user.menu.users || 0);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      await axios({
        method: 'PUT',
        url: BASE_URL + `/api/oneblock/users/edit/` + user.Id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "x-api-key": API_KEY,
          "x-client-id": CLIENT_ID,
        },
        data: {
          firstname : firstName,
          lastname : lastName
        }
      })

      if(
        activity != user.menu.activity ||
        tracebility != user.menu.tracebility ||
        users != user.menu.users
      ) {
        await axios({
          method: 'POST',
          url: BASE_URL + `/api/oneblock/permission/share/menu`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "x-api-key": API_KEY,
            "x-client-id": CLIENT_ID,
          },
          data: {
            user_shared_id : user.Id,
            menu : [
                {
                    users,
                    data: user.menu.data || 0,
                    activity,
                    tracebility
                }
            ]
          }
        })
      };
      
      setIsLoading(false);
      getNotify(notifyRef, "success", "Sukses", "Berhasil menyimpan perubahan");
      
    } catch (error) {
      setIsLoading(false);
      getNotify(notifyRef, "danger", "Failed", "Gagal menyimpan perubahan");
    }
    // axios
    //   .put(
    //     BASE_URL + `/api/oneblock/users/edit-profile`,
    //     {
    //       id: user.id,
    //       firstname: firstName,
    //       lastname: lastName,
    //       action: "update",
    //     },
    //     {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("accessToken"),
    //         "x-api-key": API_KEY,
    //         "x-client-id": CLIENT_ID,
    //       },
    //     }
    //   )
  };

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-username">
              Nama Depan
            </label>

            <Input
              id="input-username"
              placeholder="Username"
              type="text"
              defaultValue={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-last-name">
              Nama Belakang
            </label>

            <Input
              id="input-last-name"
              placeholder=""
              type="text"
              defaultValue={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>

      { localStorage.getItem('role') == 'admin' && user?.role == 'user' && (
        <Col className="mb-4">
          <Row>
              <FormGroup
                check
                inline
              >
                <Input
                  type="checkbox"
                  id="user-feature"
                  checked={users}
                  onChange={e => setUsers(e.target.checked ? 1 : 0)}
                />
                <Label
                  check
                  className="form-control-label"
                  for="user-feature"
                >
                  Users
                </Label>
              </FormGroup>
              <FormGroup
                check
                inline
              >
                <Input
                  type="checkbox"
                  id="activity-feature"
                  checked={activity}
                  onChange={e => setActivity(e.target.checked ? 1 : 0)}
                />
                <Label
                  check
                  className="form-control-label"
                  for="activity-feature"
                >
                  Activity
                </Label>
              </FormGroup>
              <FormGroup
                check
                inline
              >
                <Input
                  type="checkbox"
                  id="tracebility-feature"
                  checked={tracebility}
                  onChange={e => setTracebility(e.target.checked ? 1 : 0)}
                />
                <Label
                  check
                  className="form-control-label"
                  for="tracebility-feature"
                >
                  Tracebility
                </Label>
              </FormGroup>
          </Row>
        </Col>
      )}

      <Row>
        <Col lg="6">
          <div style={{ marginBottom: "30px" }}>
            <Button
              disabled={isLoading}
              onClick={handleSubmit}
              style={{
                backgroundColor: "#3AA76D",
                color: "white",
                border: "0px",
              }}
            >
              Save
            </Button>

            <Button
              color="danger"
              className="border-0"
              outline
              onClick={() => {
                setFirstName(user.first_name);
                setLastName(user.last_name);
              }}
            >
              Cancel
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

const EditRole = ({ user, isLoading, setIsLoading }) => {
  const notifyRef = useRef();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [role, setRole] = useState(user.role == 'admin' ? 'Admin' : 'User' || '');

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const response = await axios({
        method: 'PUT',
        url: BASE_URL + `/api/oneblock/users/edit/role/` + user.Id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        data: {
          role : role == 'Admin' ? 'admin' : 'user' || user.role
        }
      })

      setRole(response.data.data.role == 'admin' ? 'Admin' : 'User' || '')
      
      setIsLoading(false);
      getNotify(notifyRef, "success", "Sukses", "Berhasil menyimpan perubahan");
      
    } catch (error) {
      setIsLoading(false);
      getNotify(notifyRef, "danger", "Failed", "Gagal merubah role user");
    }
    // axios
    //   .put(
    //     BASE_URL + `/api/oneblock/users/edit-profile`,
    //     {
    //       id: user.id,
    //       firstname: firstName,
    //       lastname: lastName,
    //       action: "update",
    //     },
    //     {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("accessToken"),
    //         "x-api-key": API_KEY,
    //         "x-client-id": CLIENT_ID,
    //       },
    //     }
    //   )
  };

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label d-block">
              Set User Role
            </label>

            <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
              <DropdownToggle caret className="my-2">
                {role}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => setRole('Admin')}>Admin</DropdownItem>
                <DropdownItem onClick={() => setRole('User')}>User</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <div style={{ marginBottom: "30px" }}>
            <Button
              disabled={isLoading}
              onClick={handleSubmit}
              style={{
                backgroundColor: "#3AA76D",
                color: "white",
                border: "0px",
              }}
            >
              Save
            </Button>

            <Button
              color="danger"
              className="border-0"
              outline
              onClick={() => {
                setRole(user.role == 'admin' ? 'Admin' : 'User' || '');
              }}
            >
              Cancel
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

const ActivityTable = ({ user, data = [], limit, page, total, handleTableChange }) => {
  const columns = [
    {
      dataField: "tx_hash",
      text: "Tx Hash",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <>
            <CopyToClipboard text={cellContent}>
              <span>
                {cellContent.substring(0, 8)}...
                {cellContent.substring(60, 66)}
                <i
                  data-placement="top"
                  id={"tooltip" + cellContent}
                  style={{ cursor: "pointer" }}
                  className="ml-2 far fa-copy"
                />
              </span>
            </CopyToClipboard>
            <UncontrolledTooltip
              delay={0}
              trigger="hover focus"
              placement="top"
              target={"tooltip" + cellContent}
            >
              Copy to clipboard
            </UncontrolledTooltip>
          </>
        );
      },
    },
    {
      dataField: "role",
      text: "Role",
      sort: true,
      formatter: (cellContent, row) => {
        const isAdmin = cellContent == 'admin';
        return (
          <span
            className={`${isAdmin ? "text-success" : "text-info"
              } font-weight-bold`}
          >
            {isAdmin ? "Admin" : "User"}
          </span>
        );
      },
    },
    {
      dataField: "action",
      text: "Activity",
      sort: true,
      formatter: (cellContent, row) => {
        return <span className="font-weight-bolder">{cellContent.toUpperCase()}</span>;
      },
    },
    {
      dataField: "user_address",
      text: "Address",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <>
            <CopyToClipboard text={cellContent}>
              <span>
                {cellContent.substring(0, 8)}...
                {cellContent.substring(36, 42)}
                <i
                  data-placement="top"
                  id={"tooltip" + row.tx_hash + cellContent}
                  style={{ cursor: "pointer" }}
                  className="ml-2 far fa-copy"
                />
              </span>
            </CopyToClipboard>
            <UncontrolledTooltip
              delay={0}
              trigger="hover focus"
              placement="top"
              target={"tooltip" + row.tx_hash + cellContent}
            >
              Copy to clipboard
            </UncontrolledTooltip>
          </>
        );
      },
    },
    {
      dataField: "Document_address",
      text: "Contract Address",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <>
            <CopyToClipboard text={cellContent}>
              <span>
                {cellContent.substring(0, 8)}...
                {cellContent.substring(36, 42)}
                <i
                  data-placement="top"
                  id={"tooltip" + row.tx_hash + cellContent}
                  style={{ cursor: "pointer" }}
                  className="ml-2 far fa-copy"
                />
              </span>
            </CopyToClipboard>
            <UncontrolledTooltip
              delay={0}
              trigger="hover focus"
              placement="top"
              target={"tooltip" + row.tx_hash + cellContent}
            >
              Copy to clipboard
            </UncontrolledTooltip>
          </>
        );
      },
    },
    {
      dataField: "timestamp",
      text: "Timestamp",
      sort: true,
      formatter: (cellContent, row) => {
        const date = moment(cellContent);
        // const formatted = date.format("DD/MM/yyyy hh:mm:ss");
        const humanized = date.fromNow();
        return (
          <>
            <span data-palacement="top" id={"tooltip" + cellContent}>
              {humanized}
            </span>
            {/* <UncontrolledTooltip
              delay={0}
              placement="top"
              target={"tooltip" + cellContent}
            >
              {formatted}
            </UncontrolledTooltip> */}
          </>
        );
      },
    },
    {
      dataField: "user_agent",
      text: "User Agent",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <>
            {cellContent.length > 30
              ? cellContent.substring(0, 30) + "..."
              : cellContent}
          </>
        );
      },
    }
  ];

  return (
    <div className="table-responsive">
      <CustomTable
        data={data}
        page={page}
        sizePerPage={limit}
        columns={columns}
        totalSize={total}
        onTableChange={handleTableChange}
      />
    </div>
  )
}

export default Profile;
