/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import userRoutes from "userRoutes";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

function User() {
  const history = useHistory();
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const [routes, setRoutes] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(BASE_URL + `/api/oneblock/auth/profile`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "x-api-key": API_KEY,
          "x-client-id": CLIENT_ID,
        },
      })
      .then((response) => {
        if(!history.location.pathname.includes(response.data.data.role)) history.push(`/${response.data.data.role}`);

        setRoutes(
          userRoutes.map(item => {
            if(item.name == "OneTrace" && response.data.data.menu.tracebility == 0) return null
            else if(item.name == "People" && response.data.data.menu.users == 0) return null
            else if(item.name == "Activity" && response.data.data.menu.activity == 0) return null
            else if(item.name == "Data Log" && response.data.data.menu.activity == 0) return null
            else return item
          })
          .filter(item => item)
        );

      })
      .catch(err => {
        localStorage.removeItem("loggedIn");
        history.push("/auth/login");
      });
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      if (prop.layout === "/user") {
        return (
          <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < userRoutes.length; i++) {
      if (location.pathname.indexOf(userRoutes[i].layout + userRoutes[i].path) !== -1) {
        return userRoutes[i].name;
      }
    }
    return "Activities";
  };

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  return (
    <div style={{ background: "linear-gradient(#DEF7FF, #EDF2FF)" }}>
      <Sidebar
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: "/",
          imgSrc: require("assets/img/brand/logo_oneblock.svg").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/user/dashboard" />
        </Switch>
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </div>
  );
}

export default User;
