import React from "react";

const getNotify = (ref, type, title, message) => {
    let options = {
        place: "tr",
        message: <>
            <div className="alert-text"></div>
            <span className="alert-title" style={{ fontWeight: 'bold' }} data-notify="title">
                {title}
            </span>

            <div>
                <span data-notify="message"> {message}</span>
            </div>
        </>,
        type: type,
        icon: "ni ni-bell-55 mr-2",
        autoDismiss: 7,
    };
    ref.current.notificationAlert(options);
};

export default getNotify;
