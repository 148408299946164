import React, { useState, useEffect, useCallback, useRef } from "react";

import { Button, ButtonGroup, Col, Form, Input } from "reactstrap";
import {
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  Badge,
  Spinner,
  Table,
} from "reactstrap";
import { useHistory } from "react-router";
import classnames from "classnames";
import {
  Search,
  Unlock,
  ShieldDone,
  Show,
  Upload,
  User,
  Lock,
} from "react-iconly";
import CustomTable from "components/Table";
import NotificationAlert from "react-notification-alert";
import CytoscapeComponent from "react-cytoscapejs";
import cytoscape from "cytoscape";
import cola from "cytoscape-cola";
import axios from "axios";
import getNotify  from '../../helpers/getNotify';

cytoscape.use(cola);

const BASE_URL = process.env.REACT_APP_BASE_URL;

function Tracebility() {
  const history = useHistory();

  const notifyRef = useRef();

  const [time, setTime] = useState(new Date());
  const [tabs, setTabs] = useState(1);

  const [page, setPage] = useState(1);

  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [elements, setElements] = useState([
    // { data: { id: "person_1", label: "Stella Iriana" } },
    // { data: { id: "person_2", label: "Iriana Adler" } },
    // { data: { id: "doc_1", label: "Laporan Harian Kepegawaian" }, classes: 'node_document', position: { x: 200, y: 200 } },
    // { data: { id: "person_1_read_doc_1", source: "person_1", target: "doc_1", label: 'read' }, classes: 'read_arrow' },
    // { data: { id: "person_1_update_doc_1", source: "person_1", target: "doc_1", label: 'write' }, classes: 'update_arrow' },
    // { data: { id: "person_2_read_doc_1", source: "person_2", target: "doc_1", label: 'read' }, classes: 'read_arrow' },
    // { data: { id: "person_2_upload_doc_1", source: "person_2", target: "doc_1", label: 'upload' }, classes: 'upload_arrow' },
  ]);

  const cyOptions = {
    animate: true,
    avoidOverlap: true,
    padding: 50,
    fit: true, // on every layout reposition of nodes, fit the viewport
    nodeSpacing: function (node) {
      return 50;
    },
    edgeLength: function (edge) {
      return 55;
    },
  };

  const toggleNavs = (e, state, index) => {
    e.preventDefault();
    setTabs(index);
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <p className="mb--1 font-weight-bold">
            {cellContent}
          </p>
        );
      },
    },
    {
      dataField: "timestamp",
      text: "Timestamp",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <p className="mb-0 font-light text-sm">
            {new Date(cellContent).toString()}
          </p>
        );
      },
    },
  ];

  const getTracebility = async () => {
    try {
      setIsLoading(true);

      const responseUpload = await axios({
        method: 'POST',
        url: BASE_URL + `/api/oneblock/file/get-trace/upload`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        },
        data: { address: search }
      })

      const responsePermission = await axios({
        method: 'POST',
        url: BASE_URL + `/api/oneblock/file/get-trace/permission`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        },
        data: { address: search }
      })

      const responseReads = await axios({
        method: 'POST',
        url: BASE_URL + `/api/oneblock/file/get-trace/read`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        },
        data: { address: search }
      })

      const responseAttestation = await axios({
        method: 'POST',
        url: BASE_URL + `/api/oneblock/file/get-trace/attestation`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        },
        data: { address: search }
      })

      const responseProtection = await axios({
        method: 'POST',
        url: BASE_URL + `/api/oneblock/file/get-trace/protection`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        },
        data: { address: search }
      })

      let newElements = new Array();
      newElements.push(
        { data: { id: "person_1", label: responseUpload.data.data.ownerName } },
        {
          data: { id: "doc_1", label: responseUpload.data.data.title },
          classes: "node_document",
          position: { x: 200, y: 200 },
        },
        {
          data: {
            id: "person_1_upload_doc_1",
            source: "person_1",
            target: "doc_1",
            label: "Upload",
          },
          classes: "upload_arrow",
        }
      );

      responsePermission.data.data.permission.map((item, index) => {
        newElements.push({
          data: { id: `person_${(index + 1) + 1}`, label: item.name },
        });
        newElements.push({
          data: {
            id: `person_${(index + 1) + 1}_${item.role == 2 ? "update" : "read"}_doc_1`,
            source: `person_${(index + 1) + 1}`,
            target: "doc_1",
            label: item.role == 2 ? "Update" : "Read",
          },
          classes: `${item.role == 2 ? "update_arrow" : "read_arrow"}`,
        });
      });

      setElements(newElements);

      setData({
        title: responseUpload.data.data.title,
        contractAddress: responseUpload.data.data.contractAddress,
        upload: {
          name: responseUpload.data.data.ownerName,
          address: responseUpload.data.data.OwnerAddress,
          timestamp: responseUpload.data.data.timestamp
        },
        permission: responsePermission.data.data.permission,
        read: responseReads.data.data,
        protection: responseProtection.data.data,
        tracebility: {
          permission : responsePermission.data.data.permission,
          attestation : responseAttestation.data.data.attestation
        }
      })

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      getNotify(notifyRef, "danger", "Error", error.response?.data.message || "Failed to get Document Trace")
    }
  };

  useEffect(() => {
    setInterval(() => {
      setTime(new Date());
    }, 60000);
  });

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <Container className="mt--7 pb-5" fluid>
        <Row className="align-items-center">
          <div className="col-12 my-4">
            <p className="h1 text-primary font-weight-bolder">
              Document Tracker
            </p>
            <div className="d-flex w-100">
              <p className="w-50 text-white font-weight-normal">
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    getTracebility()
                  }}
                >
                <InputGroup
                  className="bg-white mt-1"
                  style={{ border: "1px solid #A4A4A4" }}
                >
                  <Input
                    value={search}
                    placeholder="search by document address..."
                    className="form-control form-control-sm border-0 my-2"
                    type="text"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <InputGroupAddon addonType="append" className="m-2">
                    <Button
                      color="primary"
                      outline
                      type="button"
                      size="sm"
                      className="rounded text-white"
                      style={{
                        background: "#4a5ca9",
                        border: "1px solid #4a5ca9",
                      }}
                      onClick={getTracebility}
                      disabled={isLoading}
                    >
                      <i
                        data-placement="top"
                        style={{ cursor: "pointer" }}
                        className="fas fa-search"
                      />
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
                </Form>
              </p>
            </div>
          </div>
        </Row>
      </Container>

      <Container className="mt--6" fluid>
        <Card className="px-3 rounded-lg">
          <div className="nav-wrapper">
            <Nav
              className="nav-fill flex-column flex-md-row"
              id="tabs-icons-text"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  aria-selected={tabs === 1}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: tabs === 1,
                  })}
                  onClick={(e) => toggleNavs(e, "tabs", 1)}
                  href="#pablo"
                  role="tab"
                >
                  <Upload set="bold" size="small" className="mb-1 mr-2" />
                  Uploads
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={tabs === 2}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: tabs === 2,
                  })}
                  onClick={(e) => toggleNavs(e, "tabs", 2)}
                  href="#pablo"
                  role="tab"
                >
                  <Lock set="bold" size="small" className="mb-1 mr-2" />
                  Permissions
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={tabs === 3}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: tabs === 3,
                  })}
                  onClick={(e) => toggleNavs(e, "tabs", 3)}
                  href="#pablo"
                  role="tab"
                >
                  <Show set="bold" size="small" className="mb-1 mr-2" />
                  Reads
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={tabs === 4}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: tabs === 4,
                  })}
                  onClick={(e) => toggleNavs(e, "tabs", 4)}
                  href="#pablo"
                  role="tab"
                >
                  <ShieldDone set="bold" size="small" className="mb-1 mr-2" />
                  Protection
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={tabs === 5}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: tabs === 5,
                  })}
                  onClick={(e) => toggleNavs(e, "tabs", 5)}
                  href="#pablo"
                  role="tab"
                >
                  <Search set="bold" size="small" className="mb-1 mr-2" />
                  Tracebility
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          <div className={`mx-4 mt-4 ${(data || isLoading) && 'pb-5'}`}>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            ) : data ? (
              <TabContent activeTab={"tabs" + tabs}>
                <TabPane tabId="tabs1">
                  <div className="d-flex mb-2 justify-content-between text-gray">
                    <p className="description font-weight-normal">
                      Document ID: {data.contractAddress}
                    </p>
                    <p className="description font-weight-normal">
                      {time.toDateString()}
                    </p>
                  </div>

                  <p className="text-xs font-light description font-weight-bold mb-0">
                    DOCUMENT NAME
                  </p>
                  <p className="h1 text-primary">{data.title}</p>
                  <p className="mt-4 text-xs font-light description font-weight-bold mb-0">
                    Uploaded By:
                  </p>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex mt-1 align-items-center">
                      <User className="text-light" size="large" set="curved" />
                      <div className="ml-3">
                        <p className="mb--1 font-weight-bold">
                          {data.upload.name}
                        </p>
                        <p className="mb-0 font-light text-sm">
                          {new Date(data.upload.timestamp).toString()}
                        </p>
                      </div>
                    </div>

                    {/* <Button color="primary" outline>
                      View in Vortex
                    </Button> */}
                  </div>
                </TabPane>
                <TabPane tabId="tabs2">
                  <div className="d-flex mb-2 justify-content-between text-gray">
                    <p className="description font-weight-normal">
                      Document ID: {data.contractAddress}
                    </p>
                    <p className="description font-weight-normal">
                      {time.toDateString()}
                    </p>
                  </div>

                  <p className="text-xs font-light description font-weight-bold mb-0">
                    TOTAL PERMISSIONS SET
                  </p>
                  <p className="h1 d-flex align-items-center font-weight-bolder text-primary">
                    {data.permission.length} time
                    {data.permission.length > 1 ? "s" : ""}{" "}
                    <i className="fa fa-question-circle text-sm ml-2 text-gray mt-1" />
                  </p>
                  <p className="mt-4 text-xs font-light description font-weight-bold mb-0">
                    Who has Permissions on this document:
                  </p>
                  <div className="d-flex align-items-end justify-content-between">
                    <div>
                      {data?.permission?.map((item, index) => (
                        <div
                          key={index}
                          className="d-flex mt-3 align-items-center"
                        >
                          <User
                            className="text-light"
                            size="large"
                            set="curved"
                          />
                          <div className="ml-3">
                            <p className="mb--1 font-weight-bold">
                              {item.name}
                            </p>
                            <p className="mb-0 font-light text-sm">
                              {new Date(item.timestamp).toString()}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* <Button className="h-100" color="primary" outline>
                      View in Vortex
                    </Button> */}
                  </div>
                </TabPane>
                <TabPane tabId="tabs3">
                  <div className="d-flex mb-2 justify-content-between text-gray">
                    <p className="description font-weight-normal">
                      Document ID: {data.contractAddress}
                    </p>
                    <p className="description font-weight-normal">
                      {time.toDateString()}
                    </p>
                  </div>

                  <p className="text-xs font-light description font-weight-bold mb-0">
                    TOTAL READS
                  </p>
                  <p className="h1 d-flex align-items-center font-weight-bolder text-primary">
                    {data?.read?.count} time{data?.read?.by?.length > 1 ? "s" : ""}{" "}
                    <i className="fa fa-question-circle text-sm ml-2 text-gray mt-1" />
                  </p>
                  
                  <div className="table-responsive table-hover ">
                    {data && (
                      <CustomTable
                        data={data?.read?.by}
                        page={page}
                        sizePerPage={10}
                        columns={columns}
                        totalSize={data?.read?.by?.length}
                        // onTableChange={handleTableChange}
                        // rowEvents={rowEvents}
                        rowStyle={{ cursor: "pointer" }}
                        // searchState={setTargetOwner}
                        // dateRange={setTargetDateRange}
                      />
                    )}
                  </div>

                  {/* <p className="mt-4 text-xs font-light description font-weight-bold mb-0">
                    Read by:
                  </p>
                  <div className="d-flex align-items-end justify-content-between">
                    <div>
                      {data?.read?.by?.map((item, index) => (
                        <div
                          key={index}
                          className="d-flex mt-3 align-items-center"
                        >
                          <User
                            className="text-light"
                            size="large"
                            set="curved"
                          />
                          <div className="ml-3">
                            <p className="mb--1 font-weight-bold">
                              {item.name}
                            </p>
                            <p className="mb-0 font-light text-sm">
                              {Date(item.timestamp)}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div> */}

                    {/* <Button className="h-100" color="primary" outline>
                      View in Vortex
                    </Button> */}
                  {/* </div> */}
                </TabPane>
                <TabPane tabId="tabs4">
                  <div className="d-flex justify-content-between text-gray mb-2">
                    <div className="align-items-center">
                      <div className="d-flex mb-4" >
                        <img
                          alt=""
                          className="avatar avatar-sm my-auto"
                          style={{ background: "transparent" }}
                          src={
                            require("assets/img/icons/common/icon-Document.png")
                              .default
                          }
                        />
                        <div className="mx-2 my-auto">
                          <p className="description font-weight-normal mb-2">
                            Document ID: {data.contractAddress}
                          </p>
                        </div>
                      </div>

                      <div className="d-flex">
                        <img
                          alt=""
                          className="avatar avatar-sm my-auto"
                          style={{ background: "transparent" }}
                          src={
                            require("assets/img/icons/common/icon-Closed lock.png")
                              .default
                          }
                        />
                        <div className="mx-2 my-auto">
                          <p className="text-xs font-light description font-weight-bold mb-0">
                            DOCUMENT SIGNATURE
                          </p>
                          <div className="d-flex align-items-center mb-0">
                            <p className="h2 font-weight-bolder text-primary" style={{ width: '50vw'}}>{data?.protection?.documentSignature?.hash}</p>
                            <i className="fa fa-question-circle text-sm ml-2 text-gray mt-1" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-center">
                      <p className="description font-weight-normal mb-4">
                        {time.toDateString()}
                      </p>
                      {Boolean(data?.protection?.documentSignature?.isValid) ? (
                        <>
                          <img
                            alt=""
                            src={
                              require("assets/img/icons/common/icon-Checkmark.png")
                                .default
                            }
                          />
                          <p className="description font-weight-normal mt-2 mb-3">
                            Data Authentic
                          </p>
                        </>
                      ) : (
                        <>
                          <img
                            alt=""
                            src={
                              require("assets/img/icons/common/icon-Misuse.png")
                                .default
                            }
                          />
                          <p className="description font-weight-normal mt-2 mb-3">
                            Data Non Authentic
                          </p>
                        </>
                      )}

                      {/* <Button color="primary" outline>
                        View in Vortex
                      </Button> */}
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="tabs5">
                  <div className="d-flex mb-2 justify-content-between text-gray">
                    <p className="description font-weight-normal">
                      Document ID: {data.contractAddress}
                    </p>
                    <p className="description font-weight-normal">
                      {time.toDateString()}
                    </p>
                  </div>

                  <Row>
                    <Col sm="7">
                      <p className="text-xs font-light description font-weight-bold mb-0">
                        DOCUMENT RELATIONS{" "}
                        <i className="fa fa-question-circle text-sm ml-2 text-gray mt-1" />
                      </p>
                      <div className="py-2 px-3 mt-2 border rounded-lg">
                        <CytoscapeComponent
                          zoom={0.8}
                          layout={{ name: "cola", edgeLength: 250 }}
                          stylesheet={[
                            {
                              selector: "node",
                              style: {
                                width: 60,
                                height: 60,
                                "text-margin-y": 40,
                                shape: "ellipse",
                                label: "data(label)",
                                "background-color": "white",
                                "text-valign": "center",
                                "background-image":
                                  require("assets/img/icons/common/user.svg")
                                    .default,
                                "background-width": "25%",
                                color: "black",
                                "overlay-color": "#93C5FD",
                                "font-size": 16,
                                "text-outline-width": 3,
                                "text-outline-color": "#fff",
                                "background-fit": "contain",
                                "text-wrap": "wrap",
                                "text-max-width": "200px",
                              },
                            },
                            {
                              selector: ".node_document",
                              style: {
                                width: 60,
                                height: 60,
                                "text-margin-y": 50,
                                shape: "round-rectangle",
                                label: "data(label)",
                                "background-color": "white",
                                "text-valign": "center",
                                "background-image":
                                  require("assets/img/icons/common/files.svg")
                                    .default,
                                "background-width": "50%",
                                "font-size": 18,
                                color: "black",
                                "overlay-color": "#C4B5FD",
                                "font-weight": 700,
                                "text-outline-width": 3,
                                "text-outline-color": "#fff",
                                "background-fit": "contain",
                                "text-wrap": "wrap",
                                "text-max-width": "200px",
                              },
                            },
                            {
                              selector: "edge",
                              style: {
                                width: 2,
                                label: "data(label)",
                                "line-color": "#adb5bd",
                                "line-opacity": 0.75,
                                "font-size": 12,
                                "target-arrow-color": "#adb5bd",
                                "curve-style": "bezier",
                                "text-outline-color": "white",
                                "text-outline-width": 2,
                              },
                            },
                            {
                              selector: ".read_arrow",
                              style: {
                                width: 2,
                                label: "data(label)",
                                "line-color": "#14B8A6",
                                color: "#134E4A",
                                "line-opacity": 0.75,
                                "target-arrow-color": "#14B8A6",
                                "curve-style": "bezier",
                                "control-point-step-size": 100,
                              },
                            },
                            {
                              selector: ".update_arrow",
                              style: {
                                width: 2,
                                label: "data(label)",
                                "line-color": "#fb6340",
                                color: "#78350F",
                                "line-opacity": 0.75,
                                "target-arrow-color": "#fb6340",
                                "curve-style": "bezier",
                                "control-point-step-size": 100,
                              },
                            },
                            {
                              selector: ".upload_arrow",
                              style: {
                                width: 2,
                                label: "data(label)",
                                "line-color": "#EB1D36",
                                color: "#B22133",
                                "line-opacity": 0.75,
                                "target-arrow-color": "#8D1524",
                                "curve-style": "bezier",
                                "control-point-step-size": 100,
                              },
                            },
                          ]}
                          elements={elements}
                          style={{ width: "100%", height: "75vh" }}
                        />
                      </div>
                    </Col>

                    <Col sm="5">
                      <p className="text-xs font-light description font-weight-bold mb-0">
                        PERMISSIONS
                      </p>

                      {!data?.tracebility?.permission?.length && (
                        <p className="ml-2 mt-2">No Permission Set</p>
                      )}

                      {data?.tracebility?.permission?.map((item, index) => (
                        <Row key={index} className="mx-0 w-100">
                          <div className="w-100 d-flex mt-2 align-items-center justify-content-between border rounded-lg py-2 px-3">
                            <span>
                              {item.address.substring(0, 4)}..
                              {item.address.substring(39, 42)}
                            </span>

                            <div className="d-flex w-100 ml-4 justify-content-between">
                              <p className="mb--1 font-weight-bold">
                                {item.name}
                              </p>
                              <h5 className="mb-0 mt-1 ml-2">
                                <Badge
                                  size="small"
                                  color={ item.role === 1 ? "warning" : "success" }
                                >
                                  { item.role === 1 ? "Read" : "Write" }
                                </Badge>
                              </h5>
                            </div>
                          </div>
                        </Row>
                      ))}

                      <p className="mt-4 text-xs font-light description font-weight-bold mb-0">
                        ATTESTATION
                      </p>
                      
                      {/* <Container style={{ height: "70vh", 'overflow-y': 'scroll' }}> */}
                        {data?.tracebility?.attestation?.map((item, index) => (
                          <Row key={index} className="mx-0 w-100">
                            <div className="w-100 d-flex mt-2 align-items-center border rounded-lg py-2 px-3">
                              <span>
                                {item.txHash.substring(0, 4)}..
                                {item.txHash.substring(63, 66)}
                              </span>
                              <div className="d-flex w-100 ml-4 justify-content-between">
                                <p className="mb--1 font-weight-bold">
                                  {item.name}
                                </p>
                                <h5 className="mb-0 mt-1 ml-2">
                                  <Badge
                                    size="small"
                                    color={
                                      item.activity === "setPermission"
                                        ? "info"
                                        : item.activity === "DownloadCount"
                                        ? "primary"
                                        : item.activity === "ViewCount"
                                        ? "success"
                                        : "danger"
                                    }
                                  >
                                    {item.activity === "ViewCount"
                                      ? "View"
                                      : item.activity === "DownloadCount"
                                      ? "Download"
                                      : item.activity === "setPermission"
                                      ? "Permission"
                                      : "Upload"}
                                  </Badge>
                                </h5>
                              </div>
                            </div>
                          </Row>
                        ))}
                      {/* </Container> */}
                    </Col>
                  </Row>

                  <div className="mt-4 d-flex align-items-end justify-content-between">
                    <div>
                      <div className="d-flex mt-2 align-items-center"></div>
                    </div>

                    {/* <Button className="h-100" color="primary" outline>
                      View in Vortex
                    </Button> */}
                  </div>
                </TabPane>
              </TabContent>
            ) : null}
          </div>
        </Card>
      </Container>
    </>
  );
}

export default Tracebility;
