import React, { useState, useEffect, useCallback } from "react";
import { Button, ButtonGroup, Col, Input } from "reactstrap";
import {
  Alert,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CustomTable from "components/Table";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { defaultErrorImg } from "assets/img/default/default-error.img";
import pdfImage from "assets/img/default/pdf.png";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

function SharedFilePage() {
  const moment = require("moment");
  const history = useHistory();

  const [alert, setAlert] = React.useState(null);
  const [languages, setLanguages] = useState([]);
  const componentRef = React.useRef(null);
  
  const [log, setLogs] = useState([]);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const openModal = (address) => {
    const json = { address: address };
    axios
      .post(
        BASE_URL + `/api/oneblock/activity-log/logs-datapoint-by-admin`,
        json,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "x-api-key": API_KEY,
            "x-client-id": CLIENT_ID,
          },
        }
      )
      .then((response) => {
        //setContacts(response.data.data)
        setLogs(response.data.data);
        setModal(!modal);
      });
  };

  // const checkImageFileType = (imageData = []) => {
  const checkImageFileType = (imageData) => {
    // if (!imageData.length) {
    if (!imageData) {
      return defaultErrorImg;
    } else if (
      // imageData[0].includes("data:image") ||
      // imageData[0].includes(".jpg") ||
      // imageData[0].includes(".jpeg") ||
      // imageData[0].includes(".png")
      imageData.includes("data:image") ||
      imageData.includes(".jpg") ||
      imageData.includes(".jpeg") ||
      imageData.includes(".png")
    ) {
      return imageData;
    } else if (
      // imageData[0].includes(".pdf") ||
      // imageData[0].includes("application/pdf")
      imageData.includes(".pdf") ||
      imageData.includes("application/pdf")
    ) {
      return [pdfImage];
    }
    return defaultErrorImg;
  };

  const columns = [
    {
      dataField: "url",
      text: "File",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <a
            className="avatar avatar-lg"
            href="#"
            onClick={(e) => e.preventDefault()}
          >
            <img
              alt={row.descriptionDataPoint}
              className="w-100 h-100"
              style={{
                objectFit: cellContent[0]?.includes("pdf") ? "contain" : "cover",
                backgroundColor: cellContent[0]?.includes("pdf")
                  ? "white"
                  : "none",
              }}
              // src={cellContent[0]}
              src={checkImageFileType(cellContent)}
            />
          </a>
        );
      },
    },
    {
      dataField: "name",
      text: "Title",
      sort: true,
      classes: "align-middle",
      formatter: (cellContent, row) => {
        return (
          <>
            <p
              id={"tooltip" + row.dataPointAddr}
              className="mb-0 font-weight-bold text-capitalize"
            >
              {cellContent.substring(0, 64)}
            </p>
            {/* <UncontrolledTooltip
              delay={0}
              placement="bottom"
              target={"tooltip" + row.dataPointAddr}
            >
              {row.descriptionDataPoint}
            </UncontrolledTooltip> */}
          </>
        );
      },
    },
    {
      dataField: "address",
      text: "Owner",
      sort: true,
      classes: "align-middle",
      formatter: (cellContent, row) => {
        return (
          <span className="name mb-0 text-sm">
            {cellContent.substring(0, 8)}...
            {cellContent.substring(36, 42)}
          </span>
        );
      },
    },
    // {
    //   text: "Method",
    //   sort: true,
    //   classes: "align-middle",
    //   formatter: (cellContent, row) => {
    //     return (
    //       <span
    //         className="text-sm font-weight-bold"
    //         style={{ color: "#00C0DA" }}
    //       >
    //         Approve
    //       </span>
    //     );
    //   },
    // },
    {
      dataField: "contract_address",
      text: "Contract Address",
      sort: true,
      classes: "align-middle",
      formatter: (cellContent, row) => {
        return (
          <span className="name mb-0 text-sm text-muted">
            {cellContent.substring(0, 8)}...
            {cellContent.substring(36, 42)}
          </span>
        );
      },
    },
    {
      dataField: "image_hash",
      text: "Hash",
      sort: true,
      classes: "align-middle",
      formatter: (cellContent, row) => {
        return (
          <span className="name mb-0 text-sm text-muted">
            {cellContent.substring(0, 8)}...
            {cellContent.substring(58, 66)}
          </span>
        );
      },
    },
    {
      dataField: "contract_address",
      text: "Action",
      sort: true,
      classes: "align-middle",
      formatter: (cellContent, row) => {
        return (
          <Button
            type="button"
            size="sm"
            className="text-white"
            style={{
              background: "#4a5ca9",
              border: "1px solid #4a5ca9",
              borderRadius: "0",
              padding: "0.6rem 1.8rem",
            }}
            onClick={() => history.push(`/${localStorage.getItem("role")}/shared-document/${row.contract_address}`)}
          >
            See Logs
          </Button>
        );
      },
    },
  ];

  // useEffect(() => {
  //   axios
  //     .get(BASE_URL + `/api/oneblock/activity-log/all-datapoint`, {
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("accessToken"),
  //         "x-api-key": API_KEY,
  //         "x-client-id": CLIENT_ID,
  //       },
  //     })
  //     .then((response) => {
  //       // const newData = response.data.data.filter((item) => item.linkDataPoint.length);
  //       // setLanguages(newData);
  //       setLanguages(response.data.data);
  //     });
  // }, []);

  return (
    <>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <FileTable title="All Files" columns={columns} />
          </div>
        </Row>
      </Container>

      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}>DataPoint Activity Log</ModalHeader>
        <ModalBody>
          <Table className="align-items-center table-flush">
            <thead className="thead-light">
              <tr>
                <th scope="col">Timestamp</th>
                <th scope="col">Tx Hash</th>
                <th scope="col">Is Admin</th>
                <th className="sort" data-sort="budget" scope="col">
                  Activity
                </th>
                <th className="sort" data-sort="status" scope="col">
                  User Address
                </th>
                <th className="sort" data-sort="completion" scope="col">
                  Data
                </th>
                <th className="sort" data-sort="name" scope="col">
                  User Agent
                </th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody className="list">
              {log.map((obj) => {
                const formatted = moment(obj.timestamp / 1000000).format(
                  "DD/MM/yyyy hh:mm:ss"
                );
                return (
                  <tr>
                    <td>{formatted}</td>
                    <td>
                      {obj.transactionHash.substring(0, 8)}...
                      {obj.transactionHash.substring(60, 66)}
                    </td>
                    <td>{obj.isAdmin == false ? "user" : "admin"}</td>
                    <td className="budget">
                      <span className="name mb-0 text-sm">{obj.activity}</span>
                    </td>
                    <td>
                      <span className="status">
                        {obj.userAddress.substring(0, 8)}...
                        {obj.userAddress.substring(36, 42)}
                      </span>
                    </td>
                    <td>{obj.data.substring(0, 32)}..</td>
                    <td>
                      {obj.userAgent.length > 30
                        ? obj.userAgent.substring(0, 30) + "..."
                        : obj.userAgent}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

const FileTable = (props) => {
  const history = useHistory();

  const { title, columns } = props;
  const [data, setData] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [targetOwner, setTargetOwner] = useState("");
  const [targetDateRange, setTargetDateRange] = useState("");

  const fetchTableData = useCallback(async () => {
    return axios
      .get(
        BASE_URL + `/api/oneblock/file/share`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "x-api-key": API_KEY,
            "x-client-id": CLIENT_ID,
          },
        }
      )
      .then((response) => {
        if (response.data.data.length) {
          setData(response.data.data);
          setTotal(response.data.total);
          setError("");
        }
      })
      .catch((e) => {
        setData([]);
        setTotal(0);
        setError(e.response?.data.message);
      });
  }, [limit, page, targetOwner, targetDateRange]);

  const handleTableChange = (type, pages) => {
    setLimit(pages.sizePerPage);
    setPage(pages.page);
    if (pages.owner) {
      setTargetOwner(pages.owner);
    }
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/${localStorage.getItem("role")}/shared-document/${row.contract_address}`);
    },
  };

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  return (
    <>
      {error && (
        <Alert color="danger">
          <span className="alert-inner--icon">
            <i className="fas fa-times-circle" />
          </span>{" "}
          <span className="alert-inner--text ml-1">
            <strong>Error: </strong>
            {error}
          </span>
        </Alert>
      )}
      <Card>
        <CardHeader className="border-0">
          <h3 className="mb-0">{title}</h3>
        </CardHeader>
        <div className="table-responsive table-hover">
          {data && (
            <CustomTable
              data={data}
              page={page}
              sizePerPage={limit}
              columns={columns}
              totalSize={total}
              onTableChange={handleTableChange}
              rowEvents={rowEvents}
              rowStyle={{ cursor: "pointer" }}
              searchState={setTargetOwner}
              dateRange={setTargetDateRange}
            />
          )}
        </div>
      </Card>
    </>
  );
};

export default SharedFilePage;
