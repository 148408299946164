import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  Button,
  Col,
  Container,
  Row,
  Badge,
  Spinner,
  Table,
  UncontrolledTooltip,
} from "reactstrap";

import axios from "axios";
import moment from "moment";
import NotificationAlert from "react-notification-alert";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ShieldDone, ShieldFail } from "react-iconly";

import Lightbox from "components/Lightbox";
import EditDocumentModal from "./components/EditDocumentModal";
import ShareDocumentModal from "./components/ShareDocumentModal";

import getNotify  from '../../../helpers/getNotify';
import { defaultErrorImg } from "assets/img/default/default-error.img";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

const Documents = ({ match }) => {
  const {
    params: { address },
  } = match;

  const notifyRef = useRef();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);
  const [logs, setLogs] = useState(null);
  const [dataJSON, setDataJSON] = useState(null);
  const [copiedText, setCopiedText] = useState("");
  const [isApprove, setIsApprove] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalShareOpen, setIsModalShareOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const checkImageFileType = (imageData = []) => {
    if (!imageData.length) {
      return defaultErrorImg;
    } else if (
      data?.url[0].includes(".jpg") ||
      data?.url[0].includes(".jpeg") ||
      data?.url[0].includes(".png")
    ) {
      return imageData;
    }
    return defaultErrorImg;
  };

  const handleDownloadQR = () => {
    if (checkImageFileType(data?.url) == data?.url) {
      axios
        .post(
          BASE_URL + `/api/oneblock/file/download`,
          // BASE_URL + `/api/oneblock/file/download-qr`,
          { address: address, link: data.url[0] },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
              "x-api-key": API_KEY,
              "x-client-id": CLIENT_ID,
            },
          }
        )
        .then((response) => {
          if (response.data.data) {
            setLoading(true);

            const a = document.createElement("a"); // Create <a>
            // a.href = response.data.data.imageWithQr; // Image Base64 Goes here
            a.href = "data:image/png;base64," + response.data.data.imageWithQr; // Image Base64 Goes here
            a.download =
              checkImageFileType(data.url) == defaultErrorImg
                ? `${data.title}.png`
                : `${data.title}.png`; // File name Here
            a.click(); // Downloaded file

            setLoading(false);
          } else {
            getNotify(notifyRef, "danger", "Gagal", "Belum berhasil disimpan");
          }
        })
        .catch((err) => {
          getNotify(notifyRef, "danger", "Gagal", "Belum berhasil disimpan");
        });
    } else if (data?.url[0].includes(".pdf")) {
      axios
        .post(
          // BASE_URL + `/api/oneblock/file/download`,
          BASE_URL + `/api/oneblock/file/download-qr`,
          { address: address, link: data.url[0] },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
              "x-api-key": API_KEY,
              "x-client-id": CLIENT_ID,
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            setLoading(true);
            const a = document.createElement("a"); // Create <a>
            a.href = response.data.data.imageWithQr; // Image Base64 Goes here
            a.download = `${data.title}.pdf`; // File name Here
            a.click(); // Downloaded file
            setLoading(false);
          } else {
            getNotify(notifyRef, "danger", "Gagal", "Belum berhasil disimpan");
          }
        })
        .catch((err) => {
          getNotify(notifyRef, "danger", "Gagal", "Belum berhasil disimpan");
        });
    } else {
      getNotify(notifyRef, "danger", "Gagal", "Belum berhasil disimpan");
    }
  };

  async function getDocumentDetail() {
    try {
      setLoading(true);
      const response = await axios.post(
        BASE_URL + `/api/oneblock/file/get-detail`,
        { address: address },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "x-api-key": API_KEY,
            "x-client-id": CLIENT_ID,
          },
        }
      )
      let data = response.data.data;
      if (data?.data[0] === "'") {
        const parsed = JSON.parse(data?.data.split("'")[1]);
        data.data = parsed;
      }

      setDataJSON(data);
      setData(response.data.data);

      const detailResponse = await axios.post(
        BASE_URL + `/api/oneblock/activity-log/logs-datapoint-by-admin`,
        { address: address },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "x-api-key": API_KEY,
            "x-client-id": CLIENT_ID,
          },
        }
      )

      setLogs(detailResponse.data.data);
      setLoading(false);
      
    } catch (error) {
      setLoading(false);
      getNotify(notifyRef, "danger", "Failed", "Failed to get Document Detail");
    }
  }

  useEffect(() => {
    getDocumentDetail()

    axios
      .post(
        BASE_URL + `/api/oneblock/file/hash-check`,
        { address: address },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "x-api-key": API_KEY,
            "x-client-id": CLIENT_ID,
          },
        }
      )
      .then((response) => {
        setIsApprove(response.data.data.hash_is_verified);
      });
  }, []);

  if (loading) {
    return (
      <div className="w-100 d-flex justify-content-center align-items-center">
        <NotificationAlert ref={notifyRef} />
        <Spinner />
      </div>
    );
  }

  return (
    <Container style={{ marginTop: "30px" }} fluid>
      <NotificationAlert ref={notifyRef} />
      <EditDocumentModal
        data={data}
        isOpen={isModalOpen}
        toggle={() => toggleModal()}
      />
      <ShareDocumentModal
        data={data}
        isOpen={isModalShareOpen}
        toggle={() => setIsModalShareOpen(!isModalShareOpen)}
      />
      

      <Card className="overflow-hidden">
        <CardBody className="">
          <Row className="m-0">
            <Col sm="3">
              {/* <Lightbox
                mode="default"
                alt={data.description}
                src={data.url}
              /> */}
              { data && <Lightbox
                mode="default"
                src={
                  checkImageFileType(data?.url) == defaultErrorImg
                    ? defaultErrorImg
                    : checkImageFileType(data?.url)
                }
                alt={data?.description}
              /> }
            </Col>

            <Col>
              <div className="d-flex justify-content-between mb-2 w-100">
                {isApprove &&
                  <Badge color="success" className="d-flex align-items-center">
                    <span className="btn-inner--icon mr-1">
                      <ShieldDone set="bold" size="small" />
                    </span>
                    <span>Authentic</span>
                  </Badge>
                }

                {!isApprove &&
                  <Badge color="danger" className="d-flex align-items-center">
                    <span className="btn-inner--icon mr-1">
                      <ShieldFail set="bold" size="small" />
                    </span>
                    <span>Integrity check failed</span>
                  </Badge>
                }

                { data && (
                  <Row>
                    <Button
                      className="d-flex btn-icon btn-3"
                      color="primary"
                      type="button"
                      size="sm"
                      onClick={() => setIsModalShareOpen(!isModalShareOpen)}
                    >
                      <span className="d-flex btn-inner--icon">
                        <i className="fa fa-share" />
                      </span>
                      <span className="btn-inner--text">Share</span>
                    </Button>
                    <Button
                      className="d-flex btn-icon btn-3"
                      color="primary"
                      type="button"
                      size="sm"
                      onClick={() => toggleModal()}
                    >
                      <span className="d-flex btn-inner--icon">
                        <i className="far fa-edit" />
                      </span>
                      <span className="btn-inner--text">Edit</span>
                    </Button>
                    <Button
                      className="d-flex align-items-center btn-icon btn-3"
                      color="primary"
                      type="button"
                      size="sm"
                      onClick={() => handleDownloadQR()}
                      disabled={loading}
                    >
                      <span className="d-flex btn-inner--icon">
                        <i className="fas fa-arrow-down" />
                      </span>
                      <span className="btn-inner--text">Download QR</span>
                    </Button>
                  </Row>
                )}
              </div>

              <div className="d-flex justify-content-between w-100">
                <div>
                  <h2 className="text-capitalize m-0">{data?.title} </h2>
                  <CopyToClipboard
                    text={data?.contract_address}
                    onCopy={() =>
                      setCopiedText({ copiedText: data?.contract_address })
                    }
                  >
                    <span
                      id="tooltip982655500"
                      className="text-sm text-muted font-weight-normal m-0"
                    >
                      ({data?.contract_address})
                    </span>
                  </CopyToClipboard>
                  <UncontrolledTooltip
                    delay={0}
                    trigger="hover focus"
                    target="tooltip982655500"
                  >
                    {copiedText === data?.contract_address
                      ? "Copied"
                      : "Copy To Clipboard"}
                  </UncontrolledTooltip>
                </div>
                <p className="w-100 text-right text-sm">
                  {moment(data?.created_at).format("LL")}
                </p>
              </div>

              <Row className="m-0">
                <p className="text-sm mt-2">{data?.description}</p>
              </Row>
            </Col>

            <Col sm="12" className="mt-3">
              <p className="font-weight-bold mb-2">Raw Data</p>
              <pre className="bg-secondary border text-gray-dark rounded px-3 py-2">
                {JSON.stringify(dataJSON?.data, null, 4)}
              </pre>
            </Col>

            {/* <Row className="mt-3 mx-n4 mb-n4 overflow-auto"> */}
              <Table className="align-items-center rounded-lg mt-3 mx-3">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Timestamp</th>
                    <th scope="col">Tx Hash</th>
                    <th scope="col">Role</th>
                    <th className="sort" data-sort="budget" scope="col">
                      Activity
                    </th>
                    <th className="sort" data-sort="status" scope="col">
                      User Address
                    </th>
                    {/* <th className="sort" data-sort="completion" scope="col">
                      Data
                    </th> */}
                    <th className="sort" data-sort="name" scope="col">
                      User Agent
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody className="list">
                  {logs &&
                    logs.map((obj) => {
                      const formatted = moment(obj.timestamp).format(
                        "DD/MM/yyyy hh:mm:ss"
                      );
                      return (
                        <tr>
                          <td>{formatted}</td>
                          <td>
                            {obj.tx_hash.substring(0, 8)}...
                            {obj.tx_hash.substring(60, 66)}
                          </td>
                          <td>{obj.role == 'user' ? "User" : "Admin"}</td>
                          <td className="budget">
                            <span className="name mb-0 text-sm">
                              {obj.action}
                            </span>
                          </td>
                          <td>
                            <span className="status">
                              {obj.user_address.substring(0, 8)}...
                              {obj.user_address.substring(36, 42)}
                            </span>
                          </td>
                          {/* <td>{obj.data.substring(0, 32)}..</td> */}
                          <td>
                            {obj.user_agent.length > 30
                              ? obj.user_agent.substring(0, 30) + "..."
                              : obj.user_agent}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            {/* </Row> */}
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default Documents;
