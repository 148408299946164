import React, { useState } from "react";

import {
  Button,
  Input,
  InputGroupAddon,
  InputGroup,
  Row,
  Col,
  FormGroup,
  Container,
  InputGroupText,
  Form,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ReactDatetime from "react-datetime";
import classnames from "classnames";
import moment from "moment";

const MySearch = (props) => {
  const [input, setInput] = useState("");

  const handleClick = () => {
    props.searchState(input);
    props.onSearch(input);
  };

  return (
    <Form onSubmit={e => {
      e.preventDefault()
      handleClick()
    }}>
      <InputGroup className="mt-1" style={{ border: "1px solid #A4A4A4" }}>
        <Input
          placeholder={props.placeholder}
          className="form-control form-control-sm border-0 my-2"
          type="text"
          onChange={(e) => setInput(e.target.value)}
        />
        <InputGroupAddon addonType="append" className="m-2">
          <Button
            color="primary"
            outline
            type="button"
            size="sm"
            className="rounded text-white"
            style={{
              background: "#4a5ca9",
              border: "1px solid #4a5ca9",
            }}
            onClick={handleClick}
          >
            <i
              data-placement="top"
              style={{ cursor: "pointer" }}
              className="fas fa-search"
            />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </Form>
  );
};

// const DateRange = (props) => {
//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");

//   return (
//     <Row className="mt-1">
//       <Col>
//         <InputGroup className="">
//           <InputGroupAddon addonType="prepend">
//             <InputGroupText>
//               <i className="ni ni-calendar-grid-58" />
//             </InputGroupText>
//           </InputGroupAddon>
//           <ReactDatetime
//             inputProps={{
//               placeholder: "Start Date here ...",
//               className: "form-control border-left-0 border-right-0 rounded-0",
//             }}
//             isValidDate={(current) => {
//               return current.isBefore();
//             }}
//             value={startDate}
//             timeFormat={false}
//             onChange={(e) => {
//               let date = e._d;
//               let lastDay = moment(date).subtract(-1, "week");

//               setStartDate(e);
//               if (lastDay._d < new Date()) {
//                 setEndDate(lastDay);
//               } else {
//                 setEndDate(moment());
//               }
//             }}
//             renderDay={(props, currentDate, selectedDate) => {
//               let classes = props.className;

//               if (
//                 startDate &&
//                 endDate &&
//                 startDate._d + "" === currentDate._d + ""
//               ) {
//                 classes += " start-date";
//               } else if (
//                 startDate &&
//                 endDate &&
//                 new Date(startDate._d + "") < new Date(currentDate._d + "") &&
//                 new Date(endDate._d + "") > new Date(currentDate._d + "")
//               ) {
//                 classes += " middle-date";
//               } else if (endDate && endDate._d + "" === currentDate._d + "") {
//                 classes += " end-date";
//               }

//               return (
//                 <td {...props} className={classes}>
//                   {currentDate.date()}
//                 </td>
//               );
//             }}
//           />
//           <ReactDatetime
//             inputProps={{
//               placeholder: "End Date here ...",
//               className: "form-control border-left-0 rounded-0",
//             }}
//             isValidDate={(current) => {
//               return moment(endDate) > moment() ? current.isAfter() : null;
//             }}
//             value={endDate}
//             timeFormat={false}
//             onChange={(e) => setEndDate(e)}
//             renderDay={(props, currentDate, selectedDate) => {
//               let classes = props.className;

//               if (
//                 startDate &&
//                 endDate &&
//                 startDate._d + "" === currentDate._d + ""
//               ) {
//                 classes += " start-date";
//               } else if (
//                 startDate &&
//                 endDate &&
//                 new Date(startDate._d + "") < new Date(currentDate._d + "") &&
//                 new Date(endDate._d + "") > new Date(currentDate._d + "")
//               ) {
//                 classes += " middle-date";
//               } else if (endDate && endDate._d + "" === currentDate._d + "") {
//                 classes += " end-date";
//               }

//               return (
//                 <td {...props} className={classes}>
//                   {currentDate.date()}
//                 </td>
//               );
//             }}
//           />
//           {/* <Button
//             color="primary"
//             outline
//             type="button"
//             size="sm"
//             className="text-white"
//             style={{
//               background: "#4a5ca9",
//               border: "1px solid #4a5ca9",
//               borderRadius: "0 0.25rem 0.25rem 0",
//             }}
//           >
//             <span>Search</span>
//           </Button> */}
//         </InputGroup>
//       </Col>
//     </Row>
//   );
// };

const initialOptions = {
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: true,
  sizePerPageRenderer: ({ onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
};

const pageButtonRenderer = ({
  page,
  active,
  disabled,
  title,
  onPageChange,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };

  return (
    <li
      key={Math.random()}
      className={classnames(
        "page-item",
        active && "active",
        disabled && "disabled "
      )}
      style={disabled ? { cursor: "not-allowed" } : null}
    >
      <a href="#" onClick={handleClick} className="page-link">
        {page === "<<" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-50 w-50"
            viewBox="0 0 20 20"
            fill="currentColor"
            style={disabled ? { opacity: 0.5 } : null}
          >
            <path
              fillRule="evenodd"
              d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {page === "<" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-50 w-50"
            viewBox="0 0 20 20"
            fill="currentColor"
            style={disabled ? { opacity: 0.5 } : null}
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {page === ">" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-50 w-50"
            viewBox="0 0 20 20"
            fill="currentColor"
            style={disabled ? { opacity: 0.5 } : null}
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {page === ">>" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-50 w-50"
            viewBox="0 0 20 20"
            fill="currentColor"
            style={disabled ? { opacity: 0.5 } : null}
          >
            <path
              fillRule="evenodd"
              d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
            <path
              fillRule="evenodd"
              d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {typeof page !== "string" && page}
      </a>
    </li>
  );
};

const CustomTable = (props) => {
  const {
    searchState,
    searchPlaceholder,
    dateRange,
    data,
    columns,
    onTableChange,
    page,
    sizePerPage,
    totalSize,
    rowEvents,
    rowStyle,
  } = props;

  return (
    <>
      <ToolkitProvider
        data={data}
        keyField="timestamp"
        columns={columns}
        search
      >
        {(props) => (
          <div className="py-4">
            <Container fluid>
              <Row className="flex-row">
                {searchState ? (
                  <Col className="pb-3" xs="12" sm="4">
                    <div id="datatable-basic_filter">
                      <MySearch
                        searchState={searchState}
                        placeholder={searchPlaceholder ? searchPlaceholder : `Search by User Address...`}
                        {...props.searchProps}
                      />
                    </div>
                  </Col>
                ) : null}
                {/* {dateRange ? (
                  <Col className="pb-3" xs="12" sm="8">
                    <DateRange dateRange={dateRange} />
                  </Col>
                ) : null} */}
              </Row>
            </Container>
            <BootstrapTable
              remote={{ pagination: true }}
              {...props.baseProps}
              bootstrap4={true}
              pagination={paginationFactory({
                ...initialOptions,
                pageButtonRenderer,
                page,
                sizePerPage,
                totalSize,
              })}
              bordered={false}
              onTableChange={onTableChange}
              rowEvents={rowEvents ? rowEvents : null}
              rowStyle={rowStyle ? rowStyle : null}
            />
          </div>
        )}
      </ToolkitProvider>
    </>
  );
};

export default CustomTable;
