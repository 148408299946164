import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import { useHistory } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import axios from "axios";
import CustomTable from "components/Table";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

function UserPage() {
  let history = useHistory();

  const columns = [
    // {
    //   dataField: "id",
    //   text: "User Id",
    //   sort: true,
    //   formatter: (cellContent, row) => {
    //     return <>{cellContent}</>;
    //   },
    // },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      formatter: (cellContent, row) => {
        return <span>{cellContent}</span>;
      },
    },
    {
      dataField: "first_name",
      text: "Name",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <Button
            size="sm"
            type="button"
            className="p-0"
            color="link"
            onClick={() => {
              history.push(`/${localStorage.getItem("role")}/profile/${row.Id}`);
            }}
          >
            {cellContent + " " + row.last_name}
          </Button>
        );
      },
    },
    {
      dataField: "role",
      text: "Role",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <span
            style={{ textTransform: "capitalize" }}
            className={cellContent === "admin" ? "text-success" : "text-info"}
          >
            {cellContent}
          </span>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: (cellContent, row) => {
        return <>{cellContent}</>;
      },
    },
  ];

  return (
    <>
      <Container className="mt--6" fluid style={{ fontFamily: "Nunito" }}>
        <Row>
          <div className="col">
            <UserTable title="All user" columns={columns} />
          </div>
        </Row>
      </Container>
    </>
  );
}

const UserTable = (props) => {
  const history = useHistory();
  const { title, columns } = props;
  const [data, setData] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);

  const fetchTableData = useCallback(async () => {
    return axios
      .get(
        BASE_URL +
        `/api/oneblock/users/get-all-users?page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "x-api-key": API_KEY,
            "x-client-id": CLIENT_ID,
          },
        }
      )
      .then((response) => {
        setData(response.data.data);
        setTotal(response.data.total);
        setError("");
        return response.data;
      })
      .catch((e) => {
        setData([]);
        setTotal(0);
        setError(e.response.data.message);
      });
  }, [limit, page]);

  const handleTableChange = (type, { page, sizePerPage }) => {
    setLimit(sizePerPage);
    setPage(page);
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/${localStorage.getItem("role")}/profile/${row.Id}`);
    },
  };

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  return (
    <>
      {error && (
        <Alert color="danger">
          <span className="alert-inner--icon">
            <i className="fas fa-times-circle" />
          </span>{" "}
          <span className="alert-inner--text ml-1">
            <strong>Error: </strong>
            {error}
          </span>
        </Alert>
      )}
      <Card>
        <CardHeader className="border-0">
          <h3 className="mb-0">{title}</h3>
        </CardHeader>
        <div className="table-responsive table-hover">
          {data && (
            <CustomTable
              data={data}
              page={page}
              sizePerPage={limit}
              columns={columns}
              totalSize={total}
              onTableChange={handleTableChange}
              rowEvents={rowEvents}
              rowStyle={{ cursor: "pointer" }}
            />
          )}
        </div>
      </Card>
    </>
  );
};

export default UserPage;
